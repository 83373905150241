<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <events />
      </v-col>
      <v-col cols="4">
        <create-event />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateEvent from "../components/CreateEvent.vue";
import Events from "../components/Events.vue";
export default {
  components: { CreateEvent, Events },
};
</script>
