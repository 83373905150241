<template>
  <v-card elevation="2" class="pa-6 mt-10">
    <h2 class="font-weight-regular mb-4">Create an online event</h2>
    <v-form v-model="valid" ref="form" @submit.prevent="onSubmit">
      <v-text-field
        v-model="eventTitle"
        label="Event Title"
        dense
        outlined
        required
        :rules="textRules"
      ></v-text-field>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-combobox
            v-model="eventDate"
            readonly
            v-bind="attrs"
            v-on="on"
            prepend-icon="mdi-calendar"
            dense
            label="Event Date"
            outlined
            required
            :rules="textRules"
          ></v-combobox>
        </template>
        <v-date-picker v-model="eventDate" no-title scrollable>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(grantDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-select
        v-model="platform"
        :items="platforms"
        label="Event platform"
        dense
        outlined
        required
        :rules="textRules"
      ></v-select>
      <v-select
        v-model="hostLocation"
        :items="locations"
        label="Hosting Location"
        dense
        outlined
        required
        :rules="textRules"
      ></v-select>
      <v-text-field
        v-model="participantCount"
        label="Maximum Participant Count"
        type="number"
        placeholder="100"
        dense
        outlined
        required
        :rules="textRules"
      ></v-text-field>
      <v-btn
        class="ma-2 ml-0"
        outlined
        color="indigo"
        :disabled="!valid"
        @click="submit"
      >
        Create Event
      </v-btn>
      <v-btn class="ma-2" outlined color="red" @click="reset">
        Clear
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      valid: false,
      textRules: [(v) => !!v || "This field is required"],
      platforms: ["Zoom", "Google Meet", "Skype", "Youtube Live"],
      locations: [
        "Cyril Ponnamperuma Auditorium",
        "Small Auditorium",
        "Video Recording Room",
      ],
    };
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    submit() {
      this.$refs.form.validate();
      this.onSubmit();
    },
    onSubmit() {
      axios
        .post("http://localhost:8091/api/v1/events", {
          eventTitle: this.eventTitle,
          eventDate: this.eventDate,
          platform: this.platform,
          hostLocation: this.hostLocation,
          participantCount: this.participantCount,
        })
        .then((response) => {
          console.log(response);
          this.$refs.form.reset();
        });
    },
  },
};
</script>
