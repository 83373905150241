<template>
  <v-card elevation="2" class="pa-6 mt-10">
    <v-card-title>
      Scheduled events
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="events"
      :search="search"
      class="elevation-1"
    ></v-data-table>
  </v-card>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "eventID" },
        { text: "Title", value: "eventTitle" },
        { text: "Location", value: "hostLocation" },
        { text: "Platform", value: "platform" },

        { text: "Date", value: "eventDate" },
        { text: "Count", value: "participantCount" },
      ],
      events: [],
    };
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    getEvents() {
      axios.get("http://localhost:8091/api/v1/events").then((response) => {
        this.events = response.data;
      });
    },
  },
};
</script>
